<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" :initial-focus="closeButtonRef" @close="closeModal" class="relative z-40">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/75 backdrop-filter backdrop-blur-xs" />
      </TransitionChild>

      <div class="fixed inset-0 h-full">
        <div class="flex h-full min-h-full items-center justify-end">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 translate-x-full"
            enter-to="opacity-100 translate-x-0"
            leave="duration-200 ease-in"
            leave-from="opacity-100 translate-x-0"
            leave-to="opacity-0 translate-x-full"
          >
            <DialogPanel
              class="relative w-full max-w-lg h-full flex flex-col transform overflow-hidden rounded-l-lg bg-white shadow-md transition-all"
            >
              <CloseButton
                ref="closeButtonRef"
                @click="closeModal"
                class="absolute top-3 right-3 text-gray-400"
              />
              <div class="px-10 py-8" :class="{ 'shadow-md': canScrollUp }">
                <DialogTitle v-if="title" as="h2" class="heading-2">
                  {{ title }}
                </DialogTitle>
                <p class="text-base text-gray-500 font-normal">
                  <slot name="introText"></slot>
                </p>
              </div>
              <!-- Slider content -->
              <div
                ref="sliderContentRef"
                class="px-10 pb-8 flex-grow overflow-y-auto overflow-x-hidden"
                @scroll="checkScrollStatus"
              >
                <slot></slot>
              </div>

              <div
                v-if="hasButton"
                class="px-10 py-6 flex gap-3 border-t border-gray-200"
                :class="{ 'shadow-t-md': canScrollDown }"
              >
                <TertiaryButton
                  v-if="secondaryButton?.text"
                  :busy="secondaryButton.busy"
                  :disabled="secondaryButton.disabled"
                  @click="emit('secondaryClick')"
                >
                  {{ secondaryButton.text }}
                </TertiaryButton>
                <PrimaryButton
                  v-if="primaryButton?.text"
                  :busy="primaryButton.busy"
                  :disabled="primaryButton.disabled"
                  @click="emit('primaryClick')"
                >
                  {{ primaryButton.text }}
                </PrimaryButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue"
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue"
import PrimaryButton from "@/components/elements/buttons/PrimaryButton.vue"
import TertiaryButton from "@/components/elements/buttons/TertiaryButton.vue"
import CloseButton from "@/components/elements/buttons/CloseButton.vue"

const props = defineProps({
  show: Boolean,
  title: String,
  primaryButton: Object, // {text: 'Save', busy: false, disabled: false, type: 'submit'}
  secondaryButton: Object,
})
const emit = defineEmits(["close", "primaryClick", "secondaryClick"])

const closeButtonRef = ref(null)
const sliderContentRef = ref(null)

const checkScrollStatus = () => {
  if (!sliderContentRef.value) return
  if (
    sliderContentRef.value.scrollHeight >
    sliderContentRef.value.offsetHeight + sliderContentRef.value.scrollTop
  ) {
    canScrollDown.value = true
  } else {
    canScrollDown.value = false
  }
  if (sliderContentRef.value.scrollTop > 0) {
    canScrollUp.value = true
  } else {
    canScrollUp.value = false
  }
}
const canScrollUp = ref(false)
const canScrollDown = ref(false)

const hasButton = computed(() => props.primaryButton?.text || props.secondaryButton?.text)

function closeModal() {
  emit("close")
}
watch(
  () => props.show,
  async () => {
    await nextTick()
    // to reset shadow state upon opening/closing slideover
    checkScrollStatus()
  },
)
</script>
